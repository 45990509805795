// Core

body {
  .app-search-wrapper { 
    border-radius: $border-radius-sm;
    padding: ($spacer * 2) 0;
    display: flex;
    align-items: center;
  }

  .app-search-icon {
    color: inherit;
  }

  .app-search-input {
    background: $white;
  }

  .search-results {
    transition: $transition-base;
    opacity: 1;
    visibility: visible;
    height: auto;
  }

  .no-search-results {
    display: flex;
    min-height: 350px;
    align-items: center;
    justify-content: center;
    transition: $transition-base;
    opacity: 1;
    visibility: visible;
    text-align: center;
    height: auto;
  }

  .search-results-hidden {
    opacity: 0;
    height: 0;
    min-height: 0;
    visibility: hidden;
    overflow: hidden;
  }

  .app-search-popper {
    z-index: 1333;
  }
}
